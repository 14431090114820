import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined"
//@ts-ignore
import Handlebar from "handlebars/dist/cjs/handlebars"
//@ts-ignore
import debounce from "lodash/debounce"
import { translatedLanguageByCountry } from "../helpers/helper"
import { translateText } from "../services/ApiServicesv2"
import CircularProgress from "@mui/material/CircularProgress" // Import CircularProgress for the loader

interface FieldData {
  title: string
  key: string
  required: boolean
  validator: string | ((value: any) => any)
  error: string
  hideTitleOnActive: boolean
  readOnly: boolean
  maxLength: number
  infoComponent: React.ComponentType<any>
  disabled: boolean
  translation: boolean
  country?: string
}

interface QkFormTextFieldProps {
  data: FieldData
  handleChange: (value: string) => void
  handleTranslateChange: (value: string) => void
  values: {
    [key: string]: string
  }
  index?: number
}

export default function QkFormTextField(props: QkFormTextFieldProps) {
  const { data, values } = props
  const [error, setError] = useState("")
  const [query, setQuery] = useState("")
  const [active, setActive] = useState(
    props.values[props.data.key] ? true : false,
  )
  const [translatedText, setTranslatedText] = useState("")
  const [isLoading, setIsLoading] = useState(false) // Track loading state
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const placeholder = Handlebar.compile(
    `${props.data.title} ${props.data.required ? "*" : ""}`,
  )({ index: (props.index ?? 0) + 1 })

  const debouncedTranslate = useCallback(
    debounce(async (query: any, country: any) => {
      setIsLoading(true) // Start the loader
      try {
        const result = await translateText(
          query || values[data?.key],
          translatedLanguageByCountry(country),
        )
        setTranslatedText(result)
        props.handleTranslateChange(result)
      } catch (error) {
        console.error("Error translating text:", error)
      } finally {
        setIsLoading(false) // Stop the loader
      }
    }, 500), // 500ms delay
    [data?.key, values],
  )

  useEffect(() => {
    if (
      (data && data?.country === "USA") ||
      (data && data?.country === "UK") ||
      (data && data?.country === "AUS-NZ")
    ) {
      return
    }

    if (!data?.translation) {
      return
    }

    const translate = async () => {
      if (data.key === "country_of_origin") {
        debouncedTranslate(query, values?.country)
      }
    }

    translate()

    // Cleanup function to cancel the debounce when the component is unmounted
    return () => {
      debouncedTranslate.cancel()
    }
  }, [values[data?.key]])

  const handleBlur = () => {
    if (props.data.validator === undefined || props.data.validator === "")
      return

    let validated = true
    if (typeof props.data.validator === "string") {
      validated = eval(props.data.validator)
    } else if (typeof props.data.validator === "function") {
      validated = props.data.validator(props.values)
    } else {
      validated = props.data.validator
    }

    if (validated) setError("")
    else setError(props.data.error ?? "Please check your provided value")

    const trimmedValue = (props.values[props.data.key] || "").trim()
    props.handleChange(trimmedValue)
  }

  useEffect(() => {
    setShowPlaceholder(!props?.data?.hideTitleOnActive && active)
    setActive(Boolean(props?.values[props?.data.key]))
  }, [props?.data, props?.values, active])

  const determineMaxLength = () => {
    if (props.data.key === "name" && props.data.title.includes("Ingredient")) {
      return 80
    }
    if (props.data.key === "name" && props.data.title.includes("Recipe Name")) {
      return 120
    }
    if (
      props.data.key === "ingredient" &&
      props.data.title.includes("Ingredient")
    ) {
      return 450
    }
    if (props.data.key === "other_information") {
      return 400
    }

    if (props.data.key === "household_quanity") {
      return 15
    }
    return 50
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s+/g, " ").trimStart()
    setActive(value !== "")
    setQuery(e.target.value)
    props.handleChange(value)
  }

  return (
    <label className={``}>
      {" "}
      {showPlaceholder && (
        <p className="absolute mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
          {placeholder}
        </p>
      )}
      <input
        type="text"
        maxLength={determineMaxLength()}
        placeholder={placeholder}
        className={classNames(
          `relative z-0 shadow-sm border w-full rounded-lg px-4 leading-8  placeholder:text-textgraylight focus:ring-black focus:border-none ${
            props.data.disabled ? "cursor-not-allowed opacity-50" : ""
          }`,
          error ? "border-red-500 border-2" : "border-bordergray",
        )}
        name={props.data.key}
        value={props.values[props.data.key] ?? ""}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={props.data.readOnly}
        disabled={props.data.disabled}
      />
      {error && <span className="text-xs text-red-500">{error}</span>}
      {props?.data?.translation && values[props?.data?.key]?.length > 0 && (
        <div className="ml-1 mt-1 flex items-center">
          <GTranslateOutlinedIcon />
          &nbsp;&nbsp;
          {isLoading ? ( // Show loader when translation is in progress
            <CircularProgress size={20} />
          ) : (
            translatedText
          )}
        </div>
      )}
      {props?.data?.infoComponent && <props.data.infoComponent />}
    </label>
  )
}
