import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import HelmetComponent from "../../common/HelmetComponent/HelmetComponent"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import AILabelValidationSchema from "../../schema/AiLabelValidationSchema"
import { BASE_ROUTES } from "../../constants/routes"
import Loading from "../Loading/Loading"
import QkFieldInput from "../../qkFormV2/QkFieldInput"
import DOMPurify from "dompurify"
import {
  getAllBrandsByBusinessId,
  getAllLabelsByBusinessIdV2,
  getLabelByBusinessId,
  generateAiReport,
  convertToImage,
  createLabel,
  fetchCategories,
  sendAiServerFailedEmail,
  uploadDigitalDriveDocument,
  createLabelReview,
} from "../../services/ApiServicesv2"
import MultiUpload from "./multiUpload"
import { fetchMessages } from "../../Utils/aiValidation/utils"
import AiLabelCategoriesModal from "../../schema/AiLabelCategoriesModal"
import CategoriesModal from "../LabelMaker/CategoriesModal"
import { FieldType } from "../../constants/enum"
import config from "../../config"
import { ppfutils } from "../../Utils/ppf"
import allowedDigitalCategories from "../../../src/schema/mapping/allowed_digital_categories.json"
import { steps } from "../../test"

const num_levels = 4
const enabled = config.LABELING.ENABLED_CATEGORIES

const defaultDropdownValues = {
  level_1: [],
  level_2: [],
  level_3: [],
  level_4: [],
}

const defaultCategoryInfo = {
  level_1: "",
  level_2: "",
  level_3: "",
  level_4: "",
}

const ValidateLabel = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [messages, setMessages] = useState([])
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [output, setOutput] = useState([])
  const [schemaData, setSchemaData] = useState([])
  const [isLabelSelected, setIsLabelSelected] = useState(false)
  const [labelData, setLabelData] = useState({
    label_id: "",
    product_name: "",
    brand_id: "",
    category: "",
    product_code: "",
  })

  const [images, setImages] = useState([])
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false)
  const [current_category_info, set_current_category_info] = useState({
    ...defaultCategoryInfo,
  })
  const [dropdown_values, set_dropdown_values] = useState({
    ...defaultDropdownValues,
  })
  let [enabledCategories, setEnabledcategory] = useState({})
  const [createLabelLoad, setCreateLabelLoad] = useState(false)
  const [categoriesData, setCategoriesData] = useState([])
  const businessId = useSelector((state) => {
    return state.login.active_business
  })
  const [existingProductName, setExistingProductName] = useState(false)
  const [existingProductCode, setExistingProductCode] = useState(false)

  const foodCategories = useSelector(
    (state) => state.categories.food_categories,
  )
  const userId = useSelector((state) => {
    return state.login.lbUserId
  })
  const [userDetails, setUserDetails] = useState()
  const [businessDetails, setbusinessDetails] = useState()
  const [productCodes, setProductCodes] = useState([])
  const [productNames, setProductNames] = useState([])

  const router = useHistory()
  const handleLabelChange = (selectedLabelId) => {
    const selectedLabel = schemaData.labels.find(
      (label) => label.key === selectedLabelId,
    )
    if (selectedLabel) {
      const selected_category = {
        category_name: selectedLabel?.raw?.label_json?.category?.description,

        category_number: selectedLabel?.raw?.label_json?.category?.category,
        id: "",
        level: selectedLabel?.raw?.label_json?.category?.key,
      }
      setOutput({
        ...output,
        label_id: selectedLabel.raw.id || selectedLabel.raw.label_id,
        product_name: selectedLabel.raw.title,
        brand_id: selectedLabel.raw.brand_id,
        category: selected_category,
      })
      setLabelData({
        label_id: selectedLabel.raw.id || selectedLabel.raw.label_id,
        product_name: selectedLabel.raw.title,
        brand_id: selectedLabel.raw.brand_id,
        category: selectedLabel.raw.category,
      })
      setIsLabelSelected(true)
    } else {
      setOutput({
        label_id: "",
        product_name: "",
        brand_id: "",
        category: "",
      })
      setLabelData({
        label_id: "",
        product_name: "",
        brand_id: "",
        category: "",
        product_code: "",
      })
      setIsLabelSelected(false)
    }
  }

  const handleChange = (key, valueUnsanitized) => {
    let value =
      typeof valueUnsanitized === "string"
        ? DOMPurify.sanitize(valueUnsanitized)
        : valueUnsanitized
    if (isLabelSelected === false) {
      setOutput({
        ...output,
        [key]: value,
      })
      setLabelData({
        ...labelData,
        [key]: value,
      })
    }

    if (isLabelSelected && key === "product_code") {
      setOutput({
        ...output,
        product_code: value,
      })
      setLabelData({
        ...labelData,
        product_code: value,
      })
    }

    if (key === "category") {
      if (isLabelSelected) {
        setIsCategoryModalOpen(false)
      } else {
        setIsCategoryModalOpen(true)
        setOutput({
          ...output,
          category: value,
          isLabelSelected: true,
        })
      }
    }

    if (key === "product_name") {
      if (Array.isArray(productNames)) {
        const isProductNameExist = productNames?.some(
          (productName) => productName?.toLowerCase() === value?.toLowerCase(),
        )
        setExistingProductName(isProductNameExist)
        if (isProductNameExist) {
          toast.warn("Product name already exists")
        }
      }
    }

    if (key === "product_code") {
      if (Array.isArray(productCodes)) {
        const isProductCodeExist = productCodes?.some(
          (productCode) => productCode?.toLowerCase() === value?.toLowerCase(),
        )
        setExistingProductCode(isProductCodeExist)
        if (isProductCodeExist) {
          toast.warn("Product code already exists")
        }
      }
    }

    if (key === "is_retail") {
      setOutput({
        ...output,
        is_retail: value,
      })
      setLabelData({
        ...labelData,
        is_retail: value,
      })
    }
  }

  const handleSave = async () => {
    if (labelData) {
      const formData = new FormData()

      formData.append("category", output.category?.category_number)

      formData.append("product_name", labelData.product_name)

      const is_retail = output.is_retail === "Retail Product" ? true : false

      formData.append("is_retail", JSON.stringify(is_retail))

      const imageBook = []

      for (let image of images) {
        formData.append("images", image)
      }

      setIsLoading(true)

      if (!output.product_name) {
        toast.error("Please add a product name")
        setIsLoading(false)
        return
      }

      if (!output.brand_id) {
        toast.error("Please select a product brand")
        setIsLoading(false)
        return
      }

      if (!output.category) {
        toast.error("Please select a category")
        setIsLoading(false)
        return
      }

      if (!output.product_code) {
        toast.error("Please add a product code")
        setIsLoading(false)
        return
      }

      if (!output.is_retail) {
        toast.error("Please select Retail or Non-Retail Product")
        setIsLoading(false)
        return
      }

      if (images.length === 0) {
        toast.error("Please attach PDF/image artworks for validation")
        setIsLoading(false)
        return
      }

      const ai_report = await generateAiReport(formData)

      if ("errors" in ai_report) {
        const payload = {
          category: output.category?.category_number,
          user_name: userDetails.first_name + " " + userDetails.last_name,
          business_name: businessDetails.business_name,
          images: ai_report?.images,
          is_retail: is_retail,
        }

        await sendAiServerFailedEmail(payload)

        setIsError(true)
        toast.error(ai_report.errors)
        setIsLoading(false)
        return
      } else if (ai_report) {
        const user_id = userId
        const business_id = businessId

        const selected_label_category = categoriesData.filter(
          (category) =>
            category.category_number === output.category.category_number,
        )

        const modified_response = {
          business_id,
          category: output.category.id || selected_label_category[0].id,
          brand_id: labelData?.brand_id,
          product_name: labelData?.product_name,
          ocr_text: ai_report?.ocr_text,
          report: {
            ...ai_report?.report,
            is_retail: is_retail,
          },
          structured_obj: ai_report?.structured_obj,
          images: ai_report?.images,
          score: ai_report?.score,
        }

        if (labelData?.product_code) {
          modified_response.product_code = labelData.product_code
        }

        setIsLoading(true)

        const result = await createLabel(modified_response)

        if (result.status === "success") {
          toast.success("Label Scanned Successfully")

          const {
            report,
            score,
            label_id,
            product_name,
            images: imageUrls,
            id,
            version,
          } = result.data

          const payload = {
            label_id: label_id,
            type: "ai_label",
            invitee: [userId],
            version: version,
          }

          await createLabelReview(payload)

          const create_digital_drive_folder_form_data = new FormData()

          create_digital_drive_folder_form_data.append("label_id", label_id)

          create_digital_drive_folder_form_data.append("folder_create", true)

          const create_digital_drive_folder = await uploadDigitalDriveDocument(
            create_digital_drive_folder_form_data,
          )

          setIsLoading(false)

          router.push(
            `${BASE_ROUTES.PPF}/ai-label-validation/${label_id}/${version}`,
          )
        } else {
          toast.error(result.message)
          setIsLoading(false)
        }
      }
    }
  }

  const getBrands = async () => {
    try {
      const brands = await getAllBrandsByBusinessId(businessId)
      setSchemaData((prev) => ({
        ...prev,
        brands: brands?.data?.map((v) => ({
          key: v.id,
          value: v.name,
          raw: {
            ...v,
          },
        })),
      }))
    } catch (error) {}
  }

  const getPpfLabelsAndData = async () => {
    try {
      const all_labels = await getAllLabelsByBusinessIdV2(businessId)

      setSchemaData((prev) => ({
        ...prev,
        labels: all_labels.data.map((label) => ({
          key: label.id,
          value: label.title,
          raw: {
            ...label,
          },
        })),
      }))
    } catch (error) {}
  }

  const loadInput = async () => {
    await getBrands()
    await getPpfLabelsAndData()
  }

  useEffect(() => {
    loadInput()
    const user_details = JSON.parse(localStorage.getItem("User_Details"))
    setUserDetails(user_details)

    const all_business_details = JSON.parse(
      localStorage.getItem("Business_Details"),
    )
    const current_business = all_business_details.find(
      (business) => business.id === businessId,
    )
    setbusinessDetails(current_business)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMessages()
        setMessages(data)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const response = await fetchCategories()
        setCategoriesData(response?.data)
      } catch (error) {}
    }

    getCategories()
  }, [])
  useEffect(async () => {
    const aiLabels = await getLabelByBusinessId(businessId)
    let prodcut_names = []
    let prodcut_codes = []

    aiLabels &&
      aiLabels.data &&
      aiLabels.data.map((label) => {
        prodcut_names.push(label.product_name)
        prodcut_codes.push(label.product_code)
      })

    setProductNames(prodcut_names)

    setProductCodes(prodcut_codes)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 4000)
    return () => clearInterval(interval)
  }, [messages])

  function closeCategoriesModal() {
    setIsCategoryModalOpen(false)
    setOutput({})
    set_dropdown_values({ ...defaultDropdownValues })
    set_current_category_info({ ...defaultCategoryInfo })
  }

  const getCategory = (current_category_info) => {
    let last_filled = null
    let keys = Object.keys(current_category_info)

    for (let i = 0; i < keys.length; i++) {
      if (
        current_category_info[keys[i]] === "" ||
        current_category_info[keys[i]] === "0"
      ) {
        last_filled = i - 1
        break
      }
    }

    if (last_filled === null) {
      last_filled = keys.length - 1
    }

    let category_description = ""
    let current_categories = enabledCategories[keys[last_filled]].filter(
      (category) =>
        category.level_value === current_category_info[keys[last_filled]],
    )

    if (current_categories.length > 0) {
      category_description = current_categories[0].category_value
    }

    return {
      category: current_category_info[keys[last_filled]],
      key: keys[last_filled],
      description: category_description,
    }
  }

  const handleCreate = async (e) => {
    setCreateLabelLoad(true)

    if (!current_category_info.level_1) {
      toast.error("Category is required!")
      setCreateLabelLoad(false)
      return
    }

    const lastDisabledCategoryLevel = Object.keys(dropdown_values)?.find(
      (key) => dropdown_values?.[key]?.length === 0,
    )
    const [levelText, levelNumber] = lastDisabledCategoryLevel?.split("_") || [
      "",
      "",
    ]
    const enabledLastCategory = lastDisabledCategoryLevel
      ? `${levelText}_${Number(levelNumber) - 1}`
      : null

    if (!lastDisabledCategoryLevel && !current_category_info.level_4) {
      toast.error("Select sub-categories to proceed")
      setCreateLabelLoad(false)
      return
    }
    if (enabledLastCategory && !current_category_info[enabledLastCategory]) {
      toast.error("Select sub-categories to proceed")
      setCreateLabelLoad(false)
      return
    }

    const currentCategory = getCategory(current_category_info)
    // ppfutils.applyFilters(steps, currentCategory.category)

    const categoryObject = {
      category: currentCategory?.description,
      category_info: current_category_info,
    }

    const categoryUuid = categoriesData?.filter((category) => {
      if (category?.category_number === currentCategory?.category) {
        setOutput({
          ...output,
          category: category,
        })
      }
    })

    setCreateLabelLoad(false)
  }

  useEffect(() => {
    const res = ppfutils.filterCategories(foodCategories, enabled, "STANDARD")
    if (res) {
      setEnabledcategory(res)
      set_dropdown_values({
        level_1: [...res?.level_1],
        level_2: [...res?.level_2],
        level_3: [...res?.level_3],
        level_4: [...res?.level_4],
      })
    }
  }, [isCategoryModalOpen])

  const currentCategoryOptionChange = (selected_value, level) => {
    const selected = selected_value
    const current_category_info1 = { ...current_category_info }
    const food_categories = enabledCategories
    const max_level_value = "level_" + num_levels
    const target_level = parseInt(level.split("_")[1]) + 1

    const target_level_value = "level_" + target_level
    let filtered_values, dropdown_values1

    current_category_info1[level] = selected
    if (level !== max_level_value) {
      filtered_values = food_categories[target_level_value].filter((obj) =>
        obj.level_value.startsWith(selected + "."),
      )

      dropdown_values1 = { ...dropdown_values }
      dropdown_values1[target_level_value] = [...filtered_values]

      for (let i = target_level + 1; i <= num_levels; i++) {
        let level_value = "level_" + i
        dropdown_values1[level_value] = []
        current_category_info1[level_value] = ""
      }

      set_current_category_info(current_category_info1)
      set_dropdown_values(dropdown_values1)
    } else {
      set_current_category_info(current_category_info1)
    }
  }

  const removeSubsequentLevelsData = (level) => {
    if (level > 4) {
      return
    }
    setOutput((prev) => ({
      ...prev,
      [`level_${level}`]: "",
    }))
    setOutput((prev) => ({
      ...prev,
      [`category_level_${level}`]: "",
    }))
    set_current_category_info((info) => ({
      ...info,
      [`level_${level}`]: "",
    }))
    if (level < 4) {
      set_dropdown_values((info) => ({
        ...info,
        [`level_${level + 1}`]: [],
      }))
    }
    removeSubsequentLevelsData(level + 1)
  }

  const handleCategoryChange = (key, value) => {
    if (key.split("_")[0] === "category") {
      currentCategoryOptionChange(value, key.replace("category_", ""))
      setOutput((prev) => ({
        ...prev,
        [key.replace("category_", "")]: value,
      }))
    }
    setOutput((prev) => ({
      ...prev,
      [key]: value,
    }))
    removeSubsequentLevelsData(Number(key?.split("_")[2]) + 1 || 5)
  }

  const handleModalAction = async () => {
    try {
      handleCreate()
    } catch (err) {
      toast.error("Category could not be created.")
    }
    setIsCategoryModalOpen(false)
  }

  return (
    <>
      {isError ? (
        <div
          style={{
            position: "absolute",
            left: `55%`,
            top: `50%`,
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
            color: "#333",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: "0",
              marginBottom: "20px",
            }}
          >
            Regrets. There seems to be a technical error here. Report this error
            with a screenshot to{" "}
            <a
              href="mailto:support@foodlabelsolutions.com"
              style={{ color: "#007bff" }}
            >
              support@foodlabelsolutions.com
            </a>
          </p>
        </div>
      ) : isLoading ? (
        <div
          style={{
            position: "absolute",
            left: `55%`,
            top: `50%`,
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
            color: "#333",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: "0",
              marginBottom: "20px",
            }}
          >
            {messages[currentMessageIndex]}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <svg
              style={{
                animation: "spin 2s linear infinite",
                width: "50px",
                height: "50px",
              }}
              viewBox="0 0 50 50"
            >
              <circle
                style={{
                  stroke: "#ccc",
                  fill: "none",
                  strokeWidth: "4",
                  strokeLinecap: "round",
                  cx: "25",
                  cy: "25",
                  r: "20",
                }}
              />
              <circle
                style={{
                  stroke: "grey",
                  fill: "none",
                  strokeWidth: "4",
                  strokeLinecap: "round",
                  strokeDasharray: "125",
                  strokeDashoffset: "125",
                  cx: "25",
                  cy: "25",
                  r: "20",
                  transformOrigin: "50% 50%",
                  animation: "dash 1.5s ease-in-out infinite",
                }}
              />
            </svg>
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
                @keyframes dash {
                  0% {
                    stroke-dashoffset: 125;
                  }
                  50% {
                    stroke-dashoffset: 25;
                    transform: rotate(135deg);
                  }
                  100% {
                    stroke-dashoffset: 125;
                    transform: rotate(450deg);
                  }
                }
              `}
            </style>
          </div>
        </div>
      ) : (
        <div>
          <HelmetComponent pageTitle={"AI Label Validation"} />
          <div className="relative font-inter w-full md:pr-40">
            <div className="text-2xl font-bold my-3">AI Label Validation</div>
            <Breadcrumbs
              linksRef={[
                {
                  page: "Dashboard",
                  link: `${BASE_ROUTES.PPF}/user-dashboard`,
                },
                {
                  page: "AI Label Validation",
                  link: `${BASE_ROUTES.PPF}/ai-label-validation`,
                },
                {
                  page: "Validation",
                  link: `${BASE_ROUTES.PPF}/ai-label-validation/validate`,
                },
              ]}
            />

            <div className="mt-4">
              {AILabelValidationSchema(schemaData, output)?.map(
                (section, index) => (
                  <React.Fragment key={index}>
                    <div className="bg-white border mt-4 p-5 rounded-lg">
                      <p className="text-lg col-span-12 mb-2 font-bold">
                        {section.name}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {section?.fields?.map((field) => {
                          if (field.type === "ai_file") {
                            return (
                              <div key={field.key} className="md:col-span-2">
                                <MultiUpload
                                  title={field.title}
                                  onChange={(files) => setImages(files)}
                                />
                              </div>
                            )
                          }

                          return (
                            <div key={field.key} className="md:col-span-1">
                              <QkFieldInput
                                data={field ?? {}}
                                values={output ?? {}}
                                handleChange={
                                  field.key === "label_id"
                                    ? handleLabelChange
                                    : (value) => handleChange(field.key, value)
                                }
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ),
              )}
            </div>

            <div className="flex justify-start">
              <button
                className={`bg-${
                  existingProductName || existingProductCode
                    ? "gray-200"
                    : "primaryyellow"
                } text-black text-sm py-3 px-4 font-bold rounded-lg inline-flex items-center`}
                onClick={handleSave}
                disabled={existingProductName || existingProductCode}
              >
                Validate Artwork
              </button>
            </div>
          </div>
        </div>
      )}
      {isCategoryModalOpen && (
        <CategoriesModal
          key={"Categories Selection"}
          data={{
            title: "New Ingredient Request",
            type: FieldType.MODAL,
            component: AiLabelCategoriesModal(output, dropdown_values),
            key: "categories_selection_modal",
            required: true,
            visible: true,
            openButtonStyle:
              "bg-primaryyellow  text-base text-black text-sm py-3 px-4 font-bold rounded-lg inline-flex items-center",
            actionButtonText: "Create",
            action: handleCreate,
          }}
          values={output ?? {}}
          isOpen={isCategoryModalOpen}
          handleChange={handleCategoryChange}
          // openModal={openCategoriesModal}
          closeModal={closeCategoriesModal}
          handleAction={handleModalAction}
          loading={createLabelLoad}
        />
      )}
    </>
  )
}

export default ValidateLabel
