import classNames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import GTranslateOutlinedIcon from "@mui/icons-material/GTranslateOutlined"
import debounce from "lodash/debounce"
import { translateText } from "../services/ApiServicesv2"
import { translatedLanguageByCountry } from "../helpers/helper"
import CircularProgress from "@mui/material/CircularProgress"

export default function QkFormTextField(props) {
  const { data, values, handleChange, handleTranslateChange } = props
  const [error, setError] = useState("")
  const [query, setQuery] = useState(props.values[props.data.key] ?? "")
  const [translatedText, setTranslatedText] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [active, setActive] = useState(
    props.values[props.data.key] ? true : false,
  )
  const placeholder = `${props.data.title} ${props.data.required ? "*" : ""}`

  const debouncedTranslate = useCallback(
    debounce(async (query, country) => {
      setIsLoading(true)
      try {
        const country_language = translatedLanguageByCountry(data?.country)

        const result = await translateText(
          query || [data?.key],
          country_language,
        )

        setTranslatedText(result)

        props.handleTranslateChange(result)
      } catch (error) {
        console.error("Error translating text:", error)
      } finally {
        setIsLoading(false)
      }
    }, 500),
    [data?.key, values, query],
  )

  useEffect(() => {
    if (
      (data && data?.country === "USA") ||
      (data && data?.country === "UK") ||
      (data && data?.country === "AUS-NZ")
    ) {
      return
    }

    if (!data?.translation) {
      return
    }

    const translate = async () => {
      if (data.key === "instructions_of_use") {
        debouncedTranslate(query, values?.country)
      }
    }

    translate()

    return () => {
      debouncedTranslate.cancel()
    }
  }, [values[data?.key], query])

  const handleBlur = () => {
    if (props.data.validator === undefined || props.data.validator === "")
      return

    let validated = true

    if (typeof props.data.validator === "string") {
      let output = props.values
      let currentValue = props.values[props.data.key]

      validated = eval(props.data.validator)
    } else {
      validated = props.data.validator
    }

    if (validated) setError("")
    else setError(props.data.error ?? "Please check your provided value")

    if (
      props.data.maxLength &&
      props.values[props.data.key].length > props.data.maxLength
    ) {
      setError(
        `Please enter a value less than ${props.data.maxLength} characters`,
      )
    }
  }

  return (
    <label
      className={`relative ${
        props.data.disabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      {!props.data.hideTitleOnActive && active && (
        <p className="absolute mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
          {placeholder}
        </p>
      )}
      <textarea
        maxLength={props.data.maxLength ?? 300}
        rows={props.data.rows}
        placeholder={placeholder}
        className={classNames(
          `${
            props.data.disabled && "cursor-not-allowed opacity-50"
          } relative z-0 shadow-sm border w-full rounded-lg px-4 leading-8  placeholder:text-textgraylight focus:ring-black focus:border-none`,
          error ? "border-red-500 border-2" : "border-bordergray",
        )}
        name={props.data.key}
        value={props.values[props.data.key] ?? ""}
        onChange={(e) => {
          if (e.target.value !== "") {
            setActive(true)
          } else {
            setActive(false)
          }

          props.handleChange(e.target.value)

          setQuery(e.target.value)
        }}
        disabled={props.data.disabled}
        onBlur={handleBlur}
        readOnly={props.data.readOnly}
      />
      {props.data.maxLength && (
        <div className="flex absolute bottom-0 right-2 p-2 text-gray-500">
          {props.values[props.data.key]?.length ?? 0}/{props.data.maxLength}
        </div>
      )}
      {(data?.country === "CA" || data?.country === "GCC") &&
        values[data?.key]?.length > 0 && (
          <div className="ml-1 mt-1 flex items-center">
            <GTranslateOutlinedIcon />
            &nbsp;&nbsp;
            {isLoading ? <CircularProgress size={20} /> : translatedText}
          </div>
        )}
      {error && <span className="text-xs text-red-500">{error}</span>}
    </label>
  )
}
